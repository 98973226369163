import React from "react"

import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import BigImage from "@src/components/BigImage"
import getAppUrl from "@src/utils/getAppUrl"
import track from "@src/utils/track"

import ScheduledTriggers from "@src/icons/screenshots/scheduled-triggers.png"
import AutomateCronJobs from "@src/icons/screenshots/automate-cron-jobs.png"
import Trainagle from "@src/icons/triangle.svg"
import OG from "@src/icons/og/OG_Scheduled_Triggers.jpeg"

import * as styles from "./styles.module.scss"
import Row from "../components/Row"
import Splash from "./components/Splash"
import Features from "./components/Features"
import ImageRow from "./components/ImageRow"

const IndexPage = ({ location }) => {
  const appUrl = getAppUrl.hook(location)
  return (
    <Layout
      title="Send Daily Reports, Monthly Newsletters or  other reminders without writing any code."
      description="Scheduled triggers allows you to trigger periodic or one-off events."
      url="https://canonic.dev/features/scheduled-triggers"
      location={location}
      ogImage={OG}
    >
      <Splash
        screenshot={ScheduledTriggers}
        title="Send Daily Reports, Monthly Newsletters or  other reminders without writing any code."
        subtitle="Scheduled triggers allows you to trigger periodic or one-off events."
      />
      <Row>
        <ImageRow
          title="Automate Events without diving into CRON and scripts."
          subtitle="Our simple scheduler UI makes it easy for you to execute your business
          logic at the right time. You can even use a CRON expression or custom
          function to determine the time of execution."
          screenshot={AutomateCronJobs}
        />
      </Row>
      <Row>
        <h2 className={styles.smallTitle}>
          Scheduled Triggers as part of your unified backend
        </h2>
        <p className={styles.subtitle}>Top Benefits</p>
        <Features
          numPerRow={4}
          features={[
            {
              title: "Interact with your data",
              subtitle:
                "Trigger a webhook whenever an API is invoked to perform an operation.",
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },
            {
              title: "Set it as a one-off event",
              subtitle:
                "Run actions in a loop until a specific condition is met. ",
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },
            {
              title: "Notifications made easy",
              subtitle:
                "Define actions based on whether a condition is true or false.",
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },
            {
              title: "Send Emails/Messages/SMS",
              subtitle:
                "Send out communication by using our Twilio, Mailgun or Slack integrations.",
              link: {
                title: "Read more >",
                url: "/docs",
              },
            },
          ]}
        />
      </Row>
      <Callout location={location} title="scheduledTriggers" />
      <CommunityCallout />
    </Layout>
  )
}

export default IndexPage
