import React from "react"
import activateOnScreen from "@src/hoc/activateOnScreen"

import * as styles from "./BigImage.module.scss"

const BigImage = activateOnScreen(({ active, innerRef, src }) => {
  const activeClassName = active ? styles.active : ""
  return (
    <section className={`${styles.bigImage} ${activeClassName}`}>
      <figure ref={innerRef} className={styles.bigImageWrapper}>
        <img src={src} className={styles.bigImageSrc} />
      </figure>
    </section>
  )
})

export default BigImage
